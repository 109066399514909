import {EventBus} from "../../events/EventBus";
import {
    FullPageModalClosedEvent,
    FullPageModalCloseEvent,
    FullPageModalEventPayload,
    FullPageModalOpenedEvent,
    FullPageModalOpenEvent
} from "../../events/Events";

const displayedCssClass: string = 'displayed';
const eventBus: EventBus = EventBus.getInstance();
const modals: Array<HTMLElement> = Array.from(document.querySelectorAll('.js-wl-fullpage-modal'));

class FullPageModalComponent {

    constructor(modal: HTMLElement) {
        eventBus.subscribe(FullPageModalOpenEvent.TYPE, (event) => {
            const payload: FullPageModalEventPayload = (event as FullPageModalOpenEvent).payload;
            if (payload.id !== modal.id) return;
            modal.classList.add(displayedCssClass);
            document.body.style.overflow = 'hidden';
            eventBus.emit(new FullPageModalOpenedEvent({id: modal.id}));
        });

        eventBus.subscribe(FullPageModalCloseEvent.TYPE, (event) => {
            const payload: FullPageModalEventPayload = (event as FullPageModalCloseEvent).payload;
            if (payload.id !== modal.id) return;
            modal.classList.remove(displayedCssClass);
            document.body.style.removeProperty('overflow');
            eventBus.emit(new FullPageModalClosedEvent({id: modal.id}));
        });

        modal.querySelector('.js-wl-fullpage-modal-close')?.addEventListener('click', () => {
            eventBus.emit(new FullPageModalCloseEvent({id: modal.id}));
        });

        const shouldCloseModalOnTapOutside = modal.classList.contains('js-close-on-tap-outside');
        if (shouldCloseModalOnTapOutside) {
            const modalBackground = modal.querySelector('.background');
            modalBackground?.addEventListener('click', function (event){
                if (event.target == modalBackground) {
                    eventBus.emit(new FullPageModalCloseEvent({id: modal.id}));
                }
            });
        }
    }
}

modals.forEach((modal: HTMLElement) => new FullPageModalComponent(modal));